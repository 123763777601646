/*
---------------
1.3 Sidebar Panel
---------------*/
@use '~@angular/material' as mat;


mat-sidenav.sidebar-panel {
  overflow-x: hidden;
  width: $sidebar-width;
  box-shadow: $base-card-box-shadow !important;

  .mat-sidenav-focus-trap > .cdk-focus-trap-content {
    position: relative;
  }

  > nav {
    min-width: $sidebar-width;
  }

  .mat-list-item div.mat-list-item-content {
    display: block;
    height: auto;
    max-height: 48px;
    overflow: hidden;
    padding: 0;
    -webkit-transition: max-height .3s cubic-bezier(.35, 0, .25, 1);
    -moz-transition: max-height .3s cubic-bezier(.35, 0, .25, 1);
    transition: max-height .3s cubic-bezier(.35, 0, .25, 1);
  }

  .mat-nav-list .mat-list-item {
    height: auto;
    background-color: transparent;
    -webkit-transition: background-color .3s cubic-bezier(.35, 0, .25, 1);
    -moz-transition: background-color .3s cubic-bezier(.35, 0, .25, 1);
    transition: background-color .3s cubic-bezier(.35, 0, .25, 1);
  }

  .mat-list-item.open > div.mat-list-item-content {
    max-height: 1000px;
    // background: mat-color($background, 'hover');
    background: rgba(0, 0, 0, 0.2);
    overflow: auto;
  }

  .mat-nav-list a {
    display: inline-block;
    flex-direction: row;
    align-items: center;
    height: 48px;
    padding: 5px 16px 5px 25px;
    color: $sidebar-menu-color;
    text-overflow: ellipsis;
    //overflow: hidden;
  }

  .sub-menu {
    padding-top: 0;
    overflow: hidden;
    -webkit-transition: .5s cubic-bezier(.35, 0, .25, 1);
    -moz-transition: .5s cubic-bezier(.35, 0, .25, 1);
    transition: .5s cubic-bezier(.35, 0, .25, 1);
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    transition-property: max-height;
  }

  .sub-menu a {
    @include padding-left(36px);
  }

  .sub-menu .sub-menu a {
    @include padding-left(36px + 16px);
  }

  .sub-menu .sub-menu .sub-menu a {
    @include padding-left(36px + 32px);
  }

  .sub-menu .sub-menu .sub-menu .sub-menu a {
    @include padding-left(36px + 48px);
  }

  .navigation {
    mat-icon:not(.menu-caret) {
      @include margin-right(24px);
    }

    .menu-caret {
      display: inline-block;
      -webkit-transition: -webkit-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
      -moz-transition: -moz-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
      -o-transition: -o-transform $transition-duration cubic-bezier(.7, 0, .3, 1);
      transition: transform $transition-duration cubic-bezier(.7, 0, .3, 1);
      @include float(right);
      @include margin-left(.3125rem);
      text-align: center;
    }

    .open > .mat-list-item-content > [appAccordionToggle] > .menu-caret {
      @include rotate(-180deg);
    }

    .menu-badge {
      display: inline-block;
      height: 16px;
      min-width: 10px;
      line-height: 18px;
      text-align: center;
      border-radius: 16px;
      font-size: 10px;
      font-weight: 700;
      padding: 0 4px;
    }

    .open > .mat-list-item-content > .sub-menu {
      max-height: 1000px;
    }
  }
}

.mat-drawer-content {
  display: flex !important;
}

.user-profile-thumb {
  color: $user-profile-name-color;

  img {
    border: $user-profile-thumb-border;
  }
}

.user-pro-wrap {
  padding: 1rem;
  position: relative;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.branding {
  padding: 1.5rem 0;

  h2 {
    color: #fff;
    margin: 0;
  }
}

.most-link {
  display: inline-block;
  color: $profile-link;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 12px;

  .mat-icon {
    margin-top: 0.8rem;
  }
}

.sidebar-panel {
  background-image: url("../../../../assets/images/bg-5.jpg"); // relative to the file it's included in
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.mat-card.settings-panel .demo-checkbox {
  height: 100% !important;
  overflow-y: auto;
}

[mat-fab].mat-fab-bottom-right {
  top: 47% !important;
  right: 0 !important;
  position: fixed !important;
  border-radius: 0 !important;
  z-index: 9;
}

.user-i {
  z-index: 99;
  position: relative;

  span {
    font-weight: bold;
    font-size: 1rem;
    padding: 0 0.5rem;
  }
}

.bg-overlay {
  position: absolute;
  left: 0;
  z-index: -1;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.85;
  background-image: $main-bg-color;
}

.mat-sidenav-content {
  overflow-x: hidden !important;
  padding: 0 !important;
  background: $main-content;
  display: flex;
}

.body-container {
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 2rem;
  margin-bottom: 2rem;
}

@media(max-width: 991px) {
  .body-container {
    padding: 1rem;
  }
  html {
    font-size: 14px;
  }
}

body {
  .opt-side {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .app {
    height: 100%;
    overflow: hidden;
  }

  .app-inner {
    height: 100%;
  }

  .main-nav {
    height: calc(100% - 64px);
  }
}

.m-gap {
  margin-bottom: $card-spacing;
}

.p-gap {
  padding-left: $card-spacing * 0.5;
  padding-right: $card-spacing * 0.5;
}

@include media-breakpoint-up(md) {
  /* Collapsed sidebar */
  .collapsed-sidebar {
    .mat-sidenav-backdrop {
      display: none;
    }

    .branding {
      span {
        display: none;
      }
    }

    .user-i {
      span {
        display: none;
      }
    }

    &.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
      margin-left: $collapsed-sidebar-width !important;
    }

    mat-sidenav.sidebar-panel {
      -webkit-transition: width .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1) !important;
      transition: width .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1) !important;
    }

    mat-sidenav.sidebar-panel:not(:hover) {
      width: $collapsed-sidebar-width;

      .sub-menu {
        display: none !important;
        visibility: hidden;
      }

      .mat-list-item > .mat-list-item-content > a {
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }

      .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
      .mat-list-item > .mat-list-item-content > a > .menu-caret {
        display: none;
      }

      .mat-list-item > .mat-list-item-content > a > span.menu-badge {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .mat-list-item > .mat-list-item-content > a > .material-icons {
        width: $collapsed-sidebar-width !important;
        padding: 0;
        margin: 0;
      }
    }
  }
  /* Compact sidebar */
  .compact-sidebar {
    &.side-panel-opened mat-sidenav.sidebar-panel ~ .mat-drawer-content {
      margin-left: $compact-sidebar-width !important;
    }

    .opt-side {
      height: 100%;
      overflow: visible;
    }

    &.app {
      overflow: auto;
      height: auto;
    }

    .branding {
      span {
        display: none;
      }
    }

    .user-i {
      span {
        display: none;
      }
    }

    mat-sidenav.sidebar-panel {
      -webkit-transition: width .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1) !important;
      transition: width .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1) !important;
    }

    mat-sidenav.sidebar-panel {
      width: $compact-sidebar-width;
      overflow: visible !important;

      .navigation > .mat-list-item > .mat-list-item-content {
        height: 60px;
        max-height: 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -moz-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -moz-justify-content: center;
        justify-content: center;
      }

      .navigation > .mat-list-item {
        position: relative;
      }

      .navigation > .mat-list-item > .mat-list-item-content {
        overflow: visible;
      }

      .navigation > .mat-list-item > .mat-list-item-content > a {
        padding-right: 0;
        padding-left: 0;
        padding-top: 9px;
        padding-bottom: 9px;
        text-align: center;
        box-sizing: content-box !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        height: 42px;
      }

      .navigation > .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge):not(.menu-caret) {
        font-size: 12px;
      }

      .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .navigation > .mat-list-item > .mat-list-item-content > a > .material-icons {
        width: $compact-sidebar-width !important;
        padding: 0;
        margin: 0;
      }

      .navigation > .mat-list-item:hover > .mat-list-item-content > .sub-menu {
        display: block !important;
        visibility: visible;
      }

      .mat-list-item > .mat-list-item-content > a > .menu-caret,
      .mat-list-item > .mat-list-item-content > a > [fxflex]
      {
        display: none;
      }

      .sub-menu {
        display: none !important;
        visibility: hidden;
        background: mat.get-color-from-palette($background, 'card');
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        position: absolute;
        top: 0;
        left: 100%;
        width: 150px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;

        a {
          padding-left: 16px;
          height: 36px;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    .sidebar-panel .mat-drawer-inner-container {
      overflow: visible !important;
    }
  }
}

//for demo
//sidebar
.header-green {
  .mat-toolbar.main-header {
    background: #009688 !important
  }
}

.color-green {
  color: #009688;
}

.header-red {
  .mat-toolbar.main-header {
    background: #f44336 !important;
  }
}

.color-red {
  color: #f44336;
}

.header-purple {
  .mat-toolbar.main-header {
    background: #9c27b0 !important;
  }
}

.color-purple {
  color: #9c27b0;
}

.header-pink {
  .mat-toolbar.main-header {
    background: #E91E63 !important
  }
}

.color-pink {
  color: #E91E63;
}

.header-blue {
  .mat-toolbar.main-header {
    background: #2196F3 !important
  }
}

.color-blue {
  color: #2196F3;
}

.header-green-deep {
  .mat-toolbar.main-header {
    background: #43A047 !important
  }
}

.color-green-deep {
  color: #43A047;
}

.header-brown {
  .mat-toolbar.main-header {
    background: #795548 !important
  }
}

.color-brown {
  color: #795548;
}

.header-orange {
  .mat-toolbar.main-header {
    background: #FF5722 !important
  }
}

.color-orange {
  color: #FF5722;
}

//Sideabr color
body .bg-img-disable .sidebar-panel {
  background: none !important;
}

.sidebar-green {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(0, 150, 136, 0.8) !important;
    }
  }
}

.sidebar-red {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(244, 67, 54, 0.8) !important;
    }
  }
}

.sidebar-purple {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(156, 39, 176, 0.8) !important;
    }
  }
}

.sidebar-pink {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(233, 30, 99, 0.8) !important;
    }
  }
}

.sidebar-blue {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(33, 150, 243, 0.8) !important;
    }
  }
}

.sidebar-green-deep {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(67, 160, 71, 0.8) !important;
    }
  }
}

.sidebar-brown {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(121, 85, 72, 0.8) !important;
    }
  }
}

.sidebar-orange {
  .sidebar-panel {
    .bg-overlay {
      background: rgba(255, 87, 34, 0.8) !important;
    }
  }
}

//.bg-1 {
//    .sidebar-panel {
//        background-image: url("../../assets/images/bg-5.jpg") !important;
//    }
//}
//
//.bg-2 {
//    .sidebar-panel {
//        background-image: url("../../assets/images/bg-2.jpg") !important;
//    }
//}
//
//.bg-3 {
//    .sidebar-panel {
//        background-image: url("../../assets/images/bg-3.jpg") !important;
//    }
//}
//
//.bg-4 {
//    .sidebar-panel {
//        background-image: url("../../assets/images/bg-4.jpg") !important;
//    }
//}

.bg-none.bg-4,
.bg-none.bg-3,
.bg-none.bg-2,
.bg-none.bg-1 {
  .sidebar-panel {
    background-image: none !important;
  }
}

.color-sidebar,
.color-header {
  .mb-1 {
    display: inline-block;
    width: 49%;
  }
}

//border active
.demo-checkbox {
  img,
  i {
    border: 3px solid transparent;
  }

  .border-active {
    img,
    i {
      border: 3px solid #ffd740;
    }

    i {
      border-radius: 10px;
    }
  }
}

//rtl
[dir="rtl"] {
  mat-sidenav.sidebar-panel {
    .sub-menu a {
      @include padding-right(36px);
      @include padding-left(16px);
    }

    .sub-menu .sub-menu a {
      @include padding-right(36px+16px);
      @include padding-left(16px);
    }

    .sub-menu .sub-menu .sub-menu a {
      @include padding-right(36px+32px);
      @include padding-left(16px);
    }

    .sub-menu .sub-menu .sub-menu .sub-menu a {
      @include padding-right(36px+48px);
      @include padding-left(16px);
    }

    .navigation {
      mat-icon:not(.menu-caret) {
        @include margin-left(24px);
        @include margin-right(0);
      }

      .menu-caret {
        @include float(left);
        @include margin-right(.3125rem);
        @include margin-left(0);
      }
    }
  }

  .img-box ul {
    left: 5%;
  }

  .social {
    margin: 90px 24% !important;
  }

  .p-list-main .p-list .bottom .left {
    float: right;
  }

  @media(max-width: 1024px) {
    .social {
      margin: 90px 15% !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .side-panel-opened[dir="rtl"] .mat-drawer-content {
    margin-left: 0 !important;
    margin-right: 15rem !important;
  }
  [dir="rtl"] {
    &.collapsed-sidebar,
    .collapsed-sidebar {
      mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content {
        margin-left: 0 !important;
        margin-right: $collapsed-sidebar-width !important;
      }

      mat-sidenav.sidebar-panel:not(:hover) {
        .mat-list-item > .mat-list-item-content > a > span.menu-badge {
          right: auto;
          left: 16px;
        }
      }
    }

    &.compact-sidebar,
    .compact-sidebar {
      mat-sidenav.sidebar-panel.mat-sidenav-opened ~ .mat-sidenav-content {
        margin-left: auto !important;
        margin-right: $compact-sidebar-width !important;
      }

      mat-sidenav.sidebar-panel {
        .navigation > .mat-list-item > .mat-list-item-content > a > span.menu-badge {
          right: auto;
          left: 16px;
        }

        .sub-menu {
          left: auto;
          right: 100%;

          a {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }
  }
  .app-dark {
    &.compact-sidebar,
    .compact-sidebar {
      mat-sidenav.sidebar-panel .sub-menu {
        background: mat.get-color-from-palette($dark-background, card);
      }
    }
  }
}

.app {
  overflow-x: hidden;
}

/* Main*/

.search-in {
  .search-form {
    transform: translateY(0%) !important;
  }
}

.widget-icon {
  width: 50px;
  height: 50px;
  line-height: 65px;
  text-align: center;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin: 0 1rem;
  background: rgba(255, 255, 255, 0.1);
}

.grid-corner {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px 12px 22px 24px;
  border-radius: 0 0 0 100%;
  transition: all 0.3s ease-in-out 0s;
}

.card-header h5,
h3 {
  margin-top: 0;
}

.base-card {
  .fa-lg {
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover {
    .fa-lg {
      font-size: 2rem;
    }
  }
}

.base-text {
  i {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
}

//card profile
.pro-card {
  padding: 3rem 2rem;
}

.pro-img {
  img {
    border-radius: 100%;
    box-shadow: $base-card-box-shadow;
  }
}

.profile-share {
  padding: 0;

  li {
    display: inline-block;

    a {
      font-size: 1rem;
      padding: 0 0.2rem;
    }
  }
}

//wheather widget
.wheather-widget {
  .wheather-today {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;

    i {
      font-size: 6rem;
    }
  }

  .wheather-future {
    padding: 1rem 0;

    i {
      font-size: 3rem;
    }
  }

  .wheather-child {
    border-left: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

//comment
.w-50 {
  width: 50px;
}

.time {
  display: block;
}

.comment-time {
  display: block;
  right: 1rem;
  font-size: 78%;
  margin-left: 2rem;
  opacity: 0.7;
}

.message-content .shadow-none.mat-card {
  padding: 1rem;
}

.mat-row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.mat-pad {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media(max-width: 580px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  body .p-2 {
    padding: 1rem !important;
  }
}

body .mat-card > :last-child {
  border: 2px;
}

//product detail
body .sidebar-container {
  height: 100%;
  position: relative;
}


@media(max-width: 543px) {
  .search-bar {
    width: 110px;
  }
  .search-bar.search-active {
    width: calc(100% - 60px);
    position: absolute;
    flex: 1 1 0%;
    z-index: 99;
    left: 50px !important;
    right: auto !important;
  }
  .sm-disable {
    display: none !important;
  }
  .search-form {
    .mat-icon {
      display: none;
    }

    input {
      padding: .95rem 0.5rem !important;
      text-indent: 0 !important;
      font-size: 0.8rem !important;
    }
  }
}

[dir="rtl"] .comment-time {
  margin-right: 2rem;
}
