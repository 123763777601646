

//List
@use '~@angular/material' as mat;

.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #FFFFFF;
  height: 506px;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module:hover,
.hover {
  -webkit-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.post-module:hover .thumbnail img,
.hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: .6;
}
.post-module .thumbnail {
  background: #000000;
  height: 400px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #e74c3c;
  width: 55px;
  height: 55px;
  padding: 5.5px 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .thumbnail img {
  display: block;
  width: 120%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 30px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  -o-transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}
.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #e74c3c;
  padding: 10px 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 26px;
  font-weight: 700;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}
.post-module .post-content .post-meta a {
  color: #999999;
  text-decoration: none;
}
.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}
.container {
  max-width: 800px;
  min-width: 640px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: '';
  display: block;
  clear: both;
}
.container .column {
  width: 50%;
  padding: 0 25px;
  -webkti-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
.container .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
.container .info {
  width: 300px;
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.container .info span {
  color: #666666;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #e74c3c;
}






//Gallery
$hover-font-color:#fff;
.hover-parent {
    box-shadow: 0 2px 4px -1px rgba( 0, 0, 0, .14), 0 4px 5px 0 rgba( 0, 0, 0, .098), 0 1px 10px 0 rgba( 0, 0, 0, .084);
    transition: 0.3s ease-in-out 0s;
    position: relative;
    overflow: hidden;
    .hover-overlay {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;
        opacity: 0;
        transition: 0.3s ease-in-out 0s;
        right: 0;
        bottom: 0;
        .hover-overlay-content {
            color: $hover-font-color;
            transition: 0.3s ease-in-out 0s;
            transform: translateY(60px);
        }
    }
    &:hover {
        box-shadow: 0 2px 4px -1px rgba( 0, 0, 0, .2), 0 4px 5px 0 rgba( 0, 0, 0, .4), 0 1px 10px 0 rgba( 0, 0, 0, .07);
        .hover-overlay {
            opacity: 1;
            .hover-overlay-content {
                transform: translate(0);
            }
        }
    }
}

//Contact Us
.more-bold {
    font-size: 4rem;
    font-weight: bold;
}

.border-right {
    border-right: 1px solid rgba(255, 255, 255, 0.8);
    position: relative;
}

.hero .hero-overlay {
    background-color: rgba(60, 59, 63, 0.8);
}

.contact-info {
    p {
        font-size: 1.3rem;
        margin-bottom: 0;
        margin-top: 0;
    }
}

//Socail friends
.friend-single {
    .card {
        padding-top: 20px;
        margin: 10px 0 20px 0;
        background-color: rgba(214, 224, 226, 0.2);
        border-top-width: 0;
        border-bottom-width: 2px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .card-heading {
            padding: 0 20px;
            margin: 0;
            &.simple {
                font-size: 20px;
                font-weight: 300;
                color: #777;
                border-bottom: 1px solid #e5e5e5;
            }
            &.image {
                img {
                    display: inline-block;
                    width: 46px;
                    height: 46px;
                    margin-right: 15px;
                    vertical-align: top;
                    border: 0;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                }
                .card-heading-header {
                    display: inline-block;
                    vertical-align: top;
                    h3 {
                        margin: 0;
                        font-size: 14px;
                        line-height: 16px;
                        color: #262626;
                    }
                    span {
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }
        .card-body {
            padding: 0 20px;
            margin-top: 20px;
        }
        .card-media {
            padding: 0 20px;
            margin: 0 -14px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .card-actions {
            min-height: 30px;
            padding: 0 20px 20px 20px;
            margin: 20px 0 0 0;
        }
        .card-comments {
            padding: 20px;
            margin: 0;
            background-color: #f8f8f8;
            .comments-collapse-toggle {
                padding: 0;
                margin: 0 20px 12px 20px;
                a,
                span {
                    padding-right: 5px;
                    overflow: hidden;
                    font-size: 12px;
                    color: #999;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .card-comments .media-heading {
        font-size: 13px;
        font-weight: bold;
    }
    .card {
        &.people {
            position: relative;
            display: inline-block;
            width: 170px;
            height: 300px;
            padding-top: 0;
            margin-left: 20px;
            overflow: hidden;
            vertical-align: top;
            &:first-child {
                margin-left: 0;
            }
            .card-top {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 170px;
                height: 150px;
                background-color: #ffffff;
                &.green {
                    background-color: #53a93f;
                }
                &.blue {
                    background-color: #427fed;
                }
            }
            .card-info {
                position: absolute;
                top: 150px;
                display: inline-block;
                width: 100%;
                height: 101px;
                overflow: hidden;
                background: #ffffff;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                .title {
                    display: block;
                    margin: 8px 14px 0 14px;
                    overflow: hidden;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 18px;
                    color: #404040;
                }
                .desc {
                    display: block;
                    margin: 8px 14px 0 14px;
                    overflow: hidden;
                    font-size: 12px;
                    line-height: 16px;
                    color: #737373;
                    text-overflow: ellipsis;
                }
            }
            .card-bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                padding: 10px 20px;
                line-height: 29px;
                text-align: center;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
        }
        &.hovercard {
            position: relative;
            padding-top: 0;
            overflow: hidden;
            text-align: center;
            background-color: rgba(214, 224, 226, 0.2);
            .cardheader {
                background: url("../../../images/night.jpg");
                background-size: cover;
                height: 135px;
            }
            .avatar {
                position: relative;
                top: -50px;
                margin-bottom: -50px;
                img {
                    width: 100px;
                    height: 100px;
                    max-width: 100px;
                    max-height: 100px;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    border: 5px solid rgba(255, 255, 255, 0.5);
                }
            }
            .info {
                padding: 4px 8px 10px;
                .title {
                    margin-bottom: 4px;
                    font-size: 24px;
                    line-height: 1;
                    color: #262626;
                    vertical-align: middle;
                }
                .desc {
                    overflow: hidden;
                    font-size: 12px;
                    line-height: 20px;
                    color: #737373;
                    text-overflow: ellipsis;
                }
            }
            .bottom {
                padding: 0 20px;
                margin-bottom: 17px;
            }
        }
    }
    .btn {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        line-height: 18px;
        margin: 0 0.3rem;
    }
}

.profile-cover {
    background-image: url("../../../images/night.jpg");;
    background-position: center center;
    background-size: cover;
    padding: 7rem 0;
}

.profile-avatar {
    top: -25%;
    left: 5%;
    position: absolute;
    width: 200px;
    img {
        border-radius: 100%;
        border: 5px solid #fff;
    }
}

.EdgeButton--tertiary {
    background-color: #fff;
    border: 1px solid #66757f;
    color: #66757f;
}

.EdgeButton,
.EdgeButton:visited {
    border-radius: 100px;
    box-shadow: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    white-space: nowrap;
}

.profile-w {
    background: #fff;
}

.profile-head {
    h4 {
        font-size: 1.3rem;
        margin-top: 0;
        font-weight: bold;
    }
}

.thumb-border {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 4px;
}

.profile-info {
    padding-top: 5rem;
    span {
        display: block;
        margin-bottom: 0.4rem;
        i {
            margin-right: 0.3rem;
            font-size: 1rem;
        }
    }
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.profile-menu {
    li {
        display: inline-block;
        >a {
            display: block;
            font-weight: bold;
            padding: 3px 15px 7px;
            color: rgba(0, 0, 0, 0.87);
        }
    }
}

.post-profile-head,
.time {
    display: inline-block;
}

.post-profile-head {
    font-size: 1rem;
    font-weight: bold;
}

.profile-content {
    background: #fff;
    padding: 1rem;
    font-size: 14px;
    margin: 0;
    vertical-align: top;
    width: 100%;
}

.profile-cont {
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
        li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding: 1rem 0;
        }
    }
}

.profile-thumb {
    width: 70px;
    border-radius: 100%;
}

.profile-i {
    span {
        margin-right: 0.2rem;
        &:last-child {
            margin: 0;
        }
    }
    p {
        margin-top: 0;
    }
}

.profile-action {
    a {
        display: inline-block;
        font-size: 1rem;
        margin-right: 0.8rem;
        &:last-child {
            margin: 0;
        }
    }
}

.time,
.twitter-uname,
.profile-action a {
    color: rgba(0, 0, 0, 0.54);
}

@media(max-width:1199px) {
    .profile-avatar {
        width: 120px;
    }
}

@media(max-width:598px) {
    .user-links{
        display: none;
    }
    .EdgeButton--tertiary {
        float: right;
        margin: 1rem;
    }
   
    .profile-avatar {
        top: -54%;
    }
    .profile-info {
        padding-top: 2rem;
    }
}
@media(max-width:959px){
    .icon-tp{
    padding-top:1rem;
    padding-bottom:1rem;
}
.profile{
    height:400px !important;
}
.mat-card.settings-panel{
    width:280px !important;
}
}


.app-dark {
    .friend-single .card.hovercard .info .desc,.friend-single .title a{
    color: #fff;
}
    .post-module .post-content{
         background:  mat.get-color-from-palette($dark-background, card);
    }
    .post-module .post-content .title{
        color:#fff;
    }
    .profile{
        background:  mat.get-color-from-palette($dark-background, card);
    }
    .pricing-table .pricing-option{
        background:  mat.get-color-from-palette($dark-background, card);
    }
    .pricing-table .pricing-option h1,.user-card-stats li a,.profile-menu li > a,.time, .twitter-uname, .profile-action a,.p-list-main .p-list .bottom .left .buy i,.p-list-main .p-list .bottom .left .buy:hover i{
        color: #fff;
    }
    .user-list-card,.profile-w,.profile-content,.p-list-main .p-list .bottom .left{
         background:  mat.get-color-from-palette($dark-background, card);
    }
}

.card-comment-widget h3.mat-line{
   font-weight: bold !important;
}

.mt-0{
   margin-bottom: 0rem !important;
}
.opacity-06{
   opacity: 0.6;
}
.activity-head {
	font-size: 1rem !important;
	margin-bottom: 1.1rem !important;
}