@use '~@angular/material' as mat;
body {
  .mat-select-panel {
    background-color: mat.get-color-from-palette($background, card);
    margin-top: 42px;
  }
  .mat-select-trigger, .mat-option {
    font-size: $font-size-base;
  }
  .mat-select-trigger {
    // height: 22px;
    // padding-bottom: 6px;
  }
  .mat-select-placeholder {
    padding-left: 0;
  }
  .app-dark .mat-select-panel {
    background-color: mat.get-color-from-palette($dark-background, card);
    margin-top: 30px;
  }
}
