/*---------------
3.1 Material Variables
---------------*/
@use '~@angular/material' as mat;

$primary: mat.define-palette(mat.$deep-purple-palette);
//$accent:  mat-palette($mat-amber, A200, A100, A400);
//$accent:  mat-palette($mat-grey, A200, A100, A400);
$accent:  mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@include mat.core();

$dark-primary: mat.define-palette(mat.$blue-grey-palette);
//$dark-accent:  mat-palette($mat-amber, A200, A100, A400);
//$dark-accent:  mat-palette($mat-grey, A200, A100, A400);
$dark-accent:  mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$deep-orange-palette);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);






