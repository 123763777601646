/* $Widget
 ------------------------------------------*/
@use "sass:math";

@use '~@angular/material' as mat;
.ngx-datatable.material {
  padding: 0;
  margin: math.div($gutter, 3);
  //  @include mat-elevation(2);
  box-shadow: 1.5px 2.6px 24px 0 rgba(0, 35, 136, 0.08);
  @include mat.elevation-transition;
  display: block;
  position: relative;
  border-radius: $border-radius-base;

  &.fullscreen {
    position: absolute !important;
    height: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.striped {
    .datatable-row-odd {
      background: mat.get-color-from-palette($background, 'hover');
    }
  }

  &.scroll-vertical {
    height: 70vh;
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {

      // &.active,
      // &.active .datatable-row-group {
      //   // @include _mat-toolbar-color(mat.define-palette(mat.$grey-palette));
      // }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 2%);
        color: #FFF;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 4%);
        color: #FFF;
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {

      &:hover,
      &:hover .datatable-row-group {
        background: mat.get-color-from-palette($background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);
        ;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {

      &:hover,
      &:hover .datatable-row-group {
        background: mat.get-color-from-palette($background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($background, 'hover'), 2%);
        ;
      }

      // &.active,
      // &.active .datatable-row-group {
      //   @include _mat-toolbar-color(mat.define-palette(mat.$grey-palette));
      // }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 2%);
        color: #FFF;
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 4%);
        color: #FFF;
      }
    }
  }

  /**
   * Shared Styles
   */
  .empty-row {
    padding: .85rem 1.2rem;
  }

  .loading-row {
    padding: .85rem 1.2rem;
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

    .datatable-header-cell {
      padding: .85rem 1.2rem;
      color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
      vertical-align: middle;

      &.longpress {
        color: #00E676;
      }

      &.dragging {
        .resize-handle {
          border-right: none;
        }
      }
    }

    .resize-handle {
      border-right: solid 1px mat.get-color-from-palette($foreground, divider);
    }
  }

  /**
   * Body Styles
   */
  .datatable-body {
    .datatable-row-detail {
      background: #f5f5f5;
      padding: 10px;
    }

    .datatable-body-row {
      .datatable-body-cell {
        padding: .85rem 1.2rem;
        box-sizing: border-box;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        -webkit-box-pack: start;
        -webkit-box-align: center;

        .datatable-body-cell-label {
          width: 100%;
        }
      }
    }

    .progress-linear {
      .container {
        background-color: rgb(170, 209, 249);

        .bar {
          background-color: rgb(16, 108, 200);
        }
      }
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat.get-color-from-palette($foreground, divider);

    .datatable-pager {
      li {
        vertical-align: middle;

        &.disabled a {
          color: rgba(0, 0, 0, 0.26) !important;
          background-color: transparent !important;
        }

        &.active a {
          background: mat.get-color-from-palette($primary);
          color: white;
          font-weight: $font-weight-base;
        }
      }
    }
  }
}

.app-dark .ngx-datatable.material {
  background: mat.get-color-from-palette($dark-background, card);
  color: mat.get-color-from-palette($dark-foreground, base);

  &.striped {
    .datatable-row-odd {
      background: mat.get-color-from-palette($dark-background, 'hover');
    }
  }

  &.single-selection,
  &.multi-selection {
    .datatable-body-row {

      // &.active,
      // &.active .datatable-row-group {
      //   @include _mat-toolbar-color(mat.define-palette(mat.$grey-palette));
      // }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette(mat.define-palette(mat.$grey-palette)), 4%);
      }
    }
  }

  &:not(.cell-selection) {
    .datatable-body-row {

      &:hover,
      &:hover .datatable-row-group {
        background: mat.get-color-from-palette($dark-background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($dark-background, 'hover'), 2%);
        ;
      }
    }
  }

  &.cell-selection {
    .datatable-body-cell {

      &:hover,
      &:hover .datatable-row-group {
        background: mat.get-color-from-palette($dark-background, 'hover');
      }

      &:focus,
      &:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($dark-background, 'hover'), 2%);
        ;
      }

      // &.active,
      // &.active .datatable-row-group {
      //   @include _mat-toolbar-color($dark-primary);
      // }

      &.active:hover,
      &.active:hover .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($dark-primary), 2%);
      }

      &.active:focus,
      &.active:focus .datatable-row-group {
        background-color: darken(mat.get-color-from-palette($dark-primary), 4%);
      }
    }
  }

  .datatable-header .datatable-row-left,
  .datatable-body .datatable-row-left,
  .datatable-header .datatable-row-right,
  .datatable-body .datatable-row-right {
    background-color: rgba(73, 73, 73, 1);
  }

  /**
   * Header Styles
   */
  .datatable-header {
    border-bottom: 1px solid mat.get-color-from-palette($dark-foreground, divider);

    .datatable-header-cell {
      color: mat.get-color-from-palette($dark-foreground, base);
    }

    .resize-handle {
      border-right: solid 1px mat.get-color-from-palette($dark-foreground, divider);
    }
  }

  /**
   * Footer Styles
   */
  .datatable-footer {
    border-top: 1px solid mat.get-color-from-palette($dark-foreground, divider);

    .datatable-pager {
      li {
        &.disabled a {
          color: rgba(255, 255, 255, 0.26) !important;
        }

        a {
          color: mat.get-color-from-palette($dark-foreground, base);
        }

        &.active a {
          background: mat.get-color-from-palette($dark-primary);
        }
      }
    }
  }
}

[dir=rtl] {
  .ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: right;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: right;
  }

  .ngx-datatable.material .empty-row {
    text-align: right;
  }

  .ngx-datatable.material .loading-row {
    text-align: right;
  }

  .ngx-datatable .datatable-footer .datatable-pager {
    text-align: left;
  }
}