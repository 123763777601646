/*
---------------
1.4 Header
---------------*/
@use '~@angular/material' as mat;

mat-toolbar {
    .toolbar-avatar {
        width: 40px;
        height: 40px;
        line-height: 24px;
    }
    .toolbar-avatar img {
        width: 40px;
        border-radius: 50%;
    }
    .notification-label {
        position: absolute;
        top: 0;
        left: 50%;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 0.8125rem;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        background-color: mat.get-color-from-palette($warn);
        color: white;
        text-align: center;
    }
    &.main-header {
        padding: 0 0.3125rem;
        background: transparent;
        position: relative;
        box-shadow: 0 7px 6px rgba(0, 0, 0, 0.05);
        background: $main-header-bg-color;
        z-index: 1;
        .search-bar {
            left: 0;
            right: 0;
            transition: all 0.3s ease-in-out 0s;
            .search-form {
                background: rgba(255, 255, 255, 1);
                position: relative;
                border-radius: $border-radius-large;
                margin-right: $gutter;
                display: block;
                margin: 0 0.3125rem;
                input {
                    font-size: 1rem;
                    padding: .95rem .75rem;
                    z-index: 2;
                    cursor: text;
                    text-indent: 30px;
                    border: none;
                    background: transparent;
                    width: 100%;
                    outline: 0;
                    border: 1px solid #eeeeee;
                }
                .material-icons {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    margin-top: -0.75rem;
                    color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
                }
            }
        }
    }
}

.branding {
    height: 64px;
    padding: 1rem 0;
    h2 {
        margin: 0;
        font-size: 1.8rem;
    }
}

.search-in {
    .search-bar {
        transform: translateY(0%) !important;
    }
}

.opt-menu {
    .mat-menu-content {
        padding-top: 0;
    }
    .head-menu {
        font-size: 1.3rem !important;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        background: linear-gradient(58deg, #f44336, #673ab7);
        color: #fff;
        &:hover {
            background: linear-gradient(58deg, #f44336, #673ab7);
        }
    }
    img {
        border-radius: 100%;
        box-shadow: 0px 0 4px 0 rgba(0, 0, 0, 0.6) !important;
        border: 2px solid rgba(0, 0, 0, 0.2);
        margin-right: 0.4rem;
    }
}

// Specific height for mobile devices in portrait mode.
@media (max-width: 599px) and (orientation: portrait) {
    mat-toolbar {
        &.main-header {
            .branding {
                padding: 0 16px 0 16px;
            }
            .logo {
                width: 87px;
                height: 20px;
                -webkit-background-size: 87px 20px;
                background-size: 87px 20px;
            }
        }
    }
}

// Specific height for mobile devices in landscape mode.
@media (max-width: 959px) and (orientation: landscape) {
    mat-toolbar {
        &.main-header {
            .search-bar {
                .search-form {
                    input {
                        padding: .65rem .75rem;
                    }
                }
            }
        }
    }
}

[dir="rtl"] {
    .main-header {
        .branding {
            padding: 0 16px 0 64px;
        }
        .search-bar {
            .search-form {
                .material-icons {
                    left: auto;
                    right: 10px;
                }
            }
        }
    }
}

.messages-list .mat-expansion-indicator {
    display: none;
}

@media(max-width:768px) {
    .name-user {
        display: none;

    }
    .img-thumb img{
     margin-right: 3rem !important;
    }
}


//
