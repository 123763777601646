/*
//--Theme Info--//

Theme Name: Optima Angular 5 Admin Template
Version: 1.0
Author: TrendSetter Themes


//-- Table of Content --//

---------------
1. Core
---------------
    1.1 Scaffolding
    1.2 Typography
    1.3 Main Sidebar
    1.4 Main Header
    1.5 Main Panel
    1.6 Sessions

---------------
2. Components
---------------
---------------
3. Material
---------------
    3.1 Material Variables
    3.2 Material Theme
    3.3 App Variables
    3.4 Mixin
    3.5 Material

---------------
3. Utilities
---------------*/
@import 'scss/material.variables';
@import 'scss/material.themes';
@import 'scss/mixins/bi-app/bi-app-ltr';
// Core Variables and Mixins
@import 'scss/app.variables';
@import 'scss/mixins';
// Core Styles
@import 'scss/core';
@import 'scss/material';
@import 'scss/utilities/utilities';
@import "font-awesome-5.css";
@import "~nouislider/dist/nouislider.css";

.text-right {
  text-align: right;
}

.mat-chip-list-wrapper .mat-standard-chip,
.mat-chip-list-wrapper input.mat-input-element {
  margin: 9px 4px !important;
}

.mat-standard-chip {
  height: 2px !important;
  min-height: 2px !important;
  padding: 15px !important;
}

.title {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
}

.float-right {
  float: right;
}

.ck-editor__editable {
  min-height: 300px !important;
}

.dialog-header {

  h2,
  h3,
  h4 {
    margin-top: 3px;
  }

  button.close-button {
    cursor: pointer;
    float: right;
    //margin-right: 10px;
  }
}

.dialog-content {
  min-height: 300px;

  mat-form-field {
    width: 100%;
  }
}

.dialog-footer,
.mat-card-footer {
  .buttons-wrap {
    align-content: flex-end;
    margin-top: 18px;
    width: 100%;

    button {
      float: right;
      margin: 0 6px;
    }
  }
}

.fa-one-half {
  font-size: 1.5em !important;
}

.animate-in {
  animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.surveys-view,
.insights-view {
  .gridster-wrap {
    gridster {
      background: #fff;
    }
  }

  .title-wrap {
    padding: 5px 10px 6px 5px;

    .dashboard-title {
      font-size: 1.5rem;
      font-weight: 400;

      .small {
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }

  gridster-item {
    padding-bottom: 10px;
  }

  .survey-list-wrap {
    padding: 10px;

    h6 {
      margin-top: 0;
    }

    &.hidden {
      display: none;
    }

    .survey-responses-wrap {
      .survey-response {
        cursor: pointer;
        //display: inline-block;
        padding: 16px 15px !important;
      }
    }

    button {
      margin-top: 15px;
      margin-right: 10px;
    }

    .action-text {
      cursor: pointer;
      font-weight: bold;
      font-stretch: extra-expanded;

      &.inline {
        //padding-left: 10px;
        //display: inline-block;
      }

      &.close {
        margin-top: 20px;
      }
    }
  }
}

.edit-field .mat-form-field-wrapper .mat-form-field-infix {
  border-top: 0.375em solid transparent;
}

.edit-field .mat-form-field-wrapper {
  padding-bottom: 0px;

  .mat-form-field-infix {
    border-top: 0.375em solid transparent;
  }
}

.ml-6 {
  margin-left: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.mt-15 {
  margin-top: 15px;
}

/*
bootstrap table theme
*/

.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 13px;

  .datatable-header {
    height: unset !important;

    .datatable-header-cell {
      vertical-align: bottom;
      padding: 0.75rem;
      border-bottom: 1px solid #d1d4d7;

      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }

  .datatable-body {
    .datatable-body-row {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #d1d4d7;

      &.datatable-row-even {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.active {
        background-color: #1483ff;
        color: #fff;
      }

      .datatable-body-cell {
        text-align: left;
        vertical-align: top;
      }
    }
  }

  .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px;

    .page-count {
      line-height: 50px;
      height: 50px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;

          &:not(.disabled) {

            &.active,
            &:hover {
              a {
                background-color: #545454;
                font-weight: bold;
              }
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #ededed;
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }
}

.mat-table {
  border: 1px solid #d1d4d7;

  .mat-row:nth-child(even) {
    background-color: white;
  }

  .mat-row:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
    border: transparent;
  }

  .mat-header-cell {
    font-weight: 600;
    font-size: 12px;
  }
}

.mat-paginator {
  background: #424242;
  color: #ededed;
}

.mat-paginator-page-size {
  display: none !important;
}

.mat-icon-button[disabled][disabled] {
  color: #ededed;
  opacity: 0.5;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: #4CAF50;
  }

  .mat-slide-toggle-bar {
    background-color: #71bd74;
  }
}

.loading {

  .pre-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-self: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .sk-cube-grid {
    bottom: 0;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }

  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background: #F38C20;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background: #EF3C24
  }

  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    //background: #EF3C24;
  }

  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    //background: #EF3C24;

  }

  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
    //background: #32AE4A;
  }

  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    //background: #32AE4A;
  }

  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    //background: #32AE4A;
  }

  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background: #32AE4A;
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {

    0%,
    70%,
    100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

}

.mat-select-search-inner {
  top: 39px !important;
}

.gridster-wrap {
  overflow-y: hidden;
  overflow-x: hidden;
}

.highcharts-wrap {
  overflow-x: hidden;
}

// following is needed for the gridster images to be downloaded correctly
// gridster {
// gridster-item {
// transition: unset !important; // if set this makes the grid shake while dragging
// }
// }
// END following is needed for the gridster images to be downloaded correctly

.mat-icon-like {
  margin-right: 16px;
  vertical-align: middle;
  font-size: 22px;
}

.cursor-pointer {
  cursor: pointer;
}

// adds breaks to the mat-checkboxes with long labels
::ng-deep mat-checkbox {
  .mat-checkbox-layout {
    white-space: normal !important;
  }
}

// rather than center the checkbox, put the checkbox in the first line
::ng-deep mat-checkbox {
  .mat-checkbox-inner-container {
    margin-top: 3px !important;
  }
}