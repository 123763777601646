//
@use '~@angular/material' as mat;
// Text
//

// Alignment

.text-justify        { text-align: justify !important; }
.text-nowrap         { white-space: nowrap !important; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .text-#{$breakpoint}-left   { text-align: left !important; }
    .text-#{$breakpoint}-right  { text-align: right !important; }
    .text-#{$breakpoint}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase      { text-transform: lowercase !important; }
.text-uppercase      { text-transform: uppercase !important; }
.text-capitalize     { text-transform: capitalize !important; }

// Size

.text-xs { font-size: 50% !important; }
.text-sm { font-size: 70% !important; }
.text-mat { font-size: 80% !important; }
.text-df { font-size: 13px !important; }
.text-lg { font-size: 110% !important; }
.text-xl { font-size: 120% !important; }
.text-xxl { font-size: 60px !important; }

// Weight and italics

.font-weight-normal  { font-weight: normal!important; }
.font-weight-bold    { font-weight: 700!important; }
.font-italic         { font-style: italic!important; }

// Contextual colors

@include text-emphasis-variant('.mat-text-muted', mat.get-color-from-palette($foreground, secondary-text));

@include text-emphasis-variant('.mat-text-default', mat.get-color-from-palette($foreground, base));

@include text-emphasis-variant('.mat-text-primary', mat.get-color-from-palette($primary));

@include text-emphasis-variant('.mat-text-warn', mat.get-color-from-palette($warn));

@include text-emphasis-variant('.mat-text-accent', mat.get-color-from-palette($accent));

.app-dark {
	@include text-emphasis-variant('.mat-text-muted', mat.get-color-from-palette($dark-foreground, secondary-text));
	@include text-emphasis-variant('.mat-text-default', mat.get-color-from-palette($dark-foreground, base));
}
//card


.column {
	padding: 0 1rem;
	margin-bottom: 2rem;
}
