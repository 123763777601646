/*---------------
3.2 Material Themes
---------------*/
@use '~@angular/material' as mat;

// Default Theme
@include mat.all-component-themes($theme);

// Dark Theme
.app-dark {  
  @include mat.all-component-themes($dark-theme);
}





